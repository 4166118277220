<template>
  <div
    class="pb-2"
    style="min-width: 1080px;"
  >
    <b-overlay
      :show="loading || !getBookingData"
      rounded="sm"
      no-fade
      variant="light"
      spinner-variant="info"
      spinner-type="grow"
      :opacity="0.9"
      no-center
      class="d-flex-center"
    >
      <template #overlay>
        <div class="text-center py-2 mt-4">
          <p class="font-weight-bolder text-airline">
            {{ $t('reservation.loading2') }}
          </p>
          <div class="text-center">
            <b-spinner variant="info" />
          </div>
        </div>
      </template>
      <div
        v-if="getBookingData"
        style="max-width: 1200px"
      >
        <TicketConfig
          v-if="!['CANCEL', 'CANCELLED'].includes(getBookingData.status)"
          :config="config"
        />
        <TicketAlertWarning />
        <vue-html2pdf
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="!isMobileView"
          :paginate-elements-by-height="1400"
          :manual-pagination="false"
          :html-to-pdf-options="pdfOptions"
          pdf-content-width="100%"
          style="min-width: 1080px;"
        >
          <section slot="pdf-content">
            <ElectronicTicket
              v-if="reservationsData"
              :from-ticket-data="getBookingData"
              :config.sync="config"
              :time-hold="timeHold"
              :from-flights="flights"
              :paxs="paxs"
              :combinate="combinateRef"
              @update:pricingInfo="$event => pricingInfo=$event"
            />
          </section>
        </vue-html2pdf>
        <div class="d-flex-center gap-3 py-1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            pill
            @click="handleCloseTab"
          >
            {{ $t('close') }} tab
          </b-button>

          <b-button
            v-if="!['CANCEL', 'CANCELLED'].includes(getBookingData.status)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient width-150 mt-lg-25"
            pill
            @click="openModalRename('img')"
          >
            {{ $t('flight.exportIMG') }}
          </b-button>

          <b-button
            v-if="!['CANCEL', 'CANCELLED'].includes(getBookingData.status)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient width-150 mt-lg-25"
            pill
            @click="openModalRename('pdf')"
          >
            {{ $t('flight.exportPDF') }}
          </b-button>

        <!-- <b-button
          v-if="!['CANCEL', 'CANCELLED'].includes(getBookingData.status)"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient width-150 mt-lg-25"
          pill
          @click="exportToFile('png')"
        >
          {{ $t('flight.exportIMG') }}
        </b-button> -->

        <!-- <b-button
          v-if="!['CANCEL', 'CANCELLED'].includes(getBookingData.status)"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient width-150 mt-lg-25"
          pill
          @click="exportToFile('pdf')"
        >
          {{ $t('flight.exportPDF') }}
        </b-button> -->
        </div>
      </div>

      <b-modal
        id="modal-rename-file"
        size="md"
        header-bg-variant="light-info"
        centered
      >
        <template #modal-header>
          <h4 class="text-heading-3 m-0">
            {{ $t('flight.renameExport') }}
          </h4>
        </template>
        <IAmOverlay :loading="loadingToExport">
          <b-form-group label-for="filename">
            <template #label>
              <div class="text-heading-5">
                {{ $t('flight.filename') }}
                <span class="text-danger">(*)</span>
              </div>
            </template>
            <b-form-input
              id="filename"
              v-model="fileNameToCustom"
              :placeholder="$t('flight.filenamePlaceholder')"
              trim
            />
          </b-form-group>
        </IAmOverlay>

        <template #modal-footer>
          <b-button
            variant="outline-danger"
            pill
            @click="cancelHandle"
          >
            {{ $t('cancel') }}
          </b-button>
          <b-button
            variant="gradient"
            pill
            :disabled="!fileNameToCustom"
            @click="exportHandle(typeToExport)"
          >
            {{ $t(`flight.export${typeToExport.toUpperCase()}`) }}
          </b-button>
        </template>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay, BSpinner, BButton, BModal, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import html2canvas from 'html2canvas-pro'
import jsPDF from 'jspdf'

import router from '@/router'
import store from '@/store'
import useGetTicketConfig from '@/views/apps/configs/ticket/useGetTicketConfig'
import { formatCurrency } from '@/@core/utils/filter'

import reservationStoreModule from '@reservation/reservationStoreModule'
import useReservationHandle from '@reservation/useReservationHandle'
import { useParseTicketData } from '@reservation/reservation-modify/components/detail/useParseTicketData'

import useToast from '@useToast'

export default {
  components: {
    BOverlay,
    BSpinner,
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
    TicketConfig: () => import('@/views/apps/configs/ticket/TicketConfig.vue'),
    ElectronicTicket: () => import('@/views/apps/configs/ticket/ElectronicTicket.vue'),
    VueHtml2pdf: () => import('vue-html2pdf'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    TicketAlertWarning: () => import('@/views/apps/configs/ticket/TicketAlertWarning.vue'),
  },
  setup() {
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    const { toastError } = useToast()
    // Register module
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }

    const {
      retrieveBooking,
      fetchBookingById,
      fetchPastDayBookingById,
      resetStore,
      getBookingData,
      loading,
    } = useReservationHandle()

    resetStore()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME)
      }
    })

    function retrieveBookingHandle(source, pnrCode) {
      return retrieveBooking({ source, pnrNumber: pnrCode })
        .catch(() => {
          toastError({ title: 'reservation.message["Booking not found"]' })
          router.replace({ name: 'apps-reservations-list' })
        })
    }
    const reservationsData = ref()

    const {
      config,
      getTicketConfig,
    } = useGetTicketConfig()

    const bookingId = ref(0)
    const timeHold = ref(null)
    const flights = ref(null)
    const paxs = ref(null)

    const combinateRef = ref(Boolean(router.currentRoute.query?.combinate) || false)
    const isPastDayBooking = ref(Boolean(router.currentRoute.query?.isPast) || false)

    function setTicketsData() {
      if (!getBookingData.value) return
      const {
        bookingId: bookingIdValue,
        timeHold: timeHoldValue,
        flights: flightsValue,
        paxs: paxsValue,
      } = useParseTicketData(getBookingData.value, combinateRef.value, config.value.locale)

      timeHold.value = timeHoldValue
      bookingId.value = bookingIdValue
      flights.value = flightsValue
      paxs.value = paxsValue
    }

    watch(() => config.value.locale, () => {
      setTicketsData()
    })

    function onLoadTicketData() {
      getTicketConfig()
      setTicketsData()
    }
    const fetchBooking = async id => {
      const idSplit = id.toString().split('-')
      let res
      if (isPastDayBooking.value) {
        res = await fetchPastDayBookingById(id)
        reservationsData.value = res.data
      } else if (idSplit.length === 1) {
        res = await fetchBookingById(id)
        reservationsData.value = res.data
      } else {
        res = await retrieveBookingHandle(idSplit[0], idSplit[1])
        reservationsData.value = res
      }
    }

    if (router.currentRoute.params.id) {
      fetchBooking(router.currentRoute.params.id)
        .then(() => {
          onLoadTicketData()
        })
    }

    const pdfOptions = {
      margin: [0, 0],
      filename: 'ticket.pdf',
      image: { type: 'jpeg', quality: 2 },
      html2canvas: { useCORS: true, scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'a3',
        orientation: 'portrait',
      },
      pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' },
    }

    const html2Pdf = ref(null)
    const fileNameToCustom = ref()

    watch(() => reservationsData.value, booking => {
      const firstPax = booking?.paxLists[0]
      const customer = `${firstPax?.lastName} ${firstPax?.firstName}`.replace(/\s/g, '')
      const bookingCode = booking?.bookingCode
      const airline = booking.itineraries?.length ? booking?.itineraries[0][0]?.airline : 'Booking'
      if (!fileNameToCustom.value) {
        fileNameToCustom.value = `${airline}_${bookingCode}_${customer}`
      }
    })

    const pricingInfo = ref({})

    // NOTE UNUSED
    // async function openUrlInNewTab(url) {
    //   window.open(url, '_blank')
    // }

    // async function exportToFile(type) {
    //   this.$bvModal.show('modal-api-loading')
    //   try {
    //     const res = await service.post(`/bookings/${bookingId.value}/export-ticket`, {
    //       ticketConfig: config.value,
    //       pricingInfo: pricingInfo.value,
    //       type,
    //     })
    //     openUrlInNewTab(res.url)

    //     this.$bvModal.hide('modal-api-loading')
    //     this.$root.toastSuccess(this.$t('ticket.exportTicketSuccess'))
    //   } catch (error) {
    //     console.log('error', error)

    //     this.$bvModal.hide('modal-api-loading')
    //     this.$root.toastError(this.$t('ticket.exportTicketgErr'))
    //   }
    // }

    const typeToExport = ref()
    const html2canvasOptions = {
      scrollX: 0,
      scrollY: -window.scrollY,
    }

    const exportToImage = async () => {
      const element = html2Pdf.value.$el.querySelector('section.content-wrapper')
      const inputMoneyDiv = element.querySelectorAll('#div-value-money')
      const inputMoneyDivClone = []
      inputMoneyDiv.forEach(el => {
        const firstChild = el.firstChild
        inputMoneyDivClone.push(firstChild.cloneNode(true))
        const newDiv = document.createElement('div')
        newDiv.textContent = formatCurrency(el.firstChild.defaultValue)
        newDiv.className = firstChild.className.replaceAll('\n', ' ').replace(/\s+/g, ' ')
        newDiv.classList.add('w-100')
        el.replaceChildren(newDiv)
      })
      const canvas = await html2canvas(element, {
        ...html2canvasOptions,
      })
        .finally(() => {
          inputMoneyDiv.forEach((el, index) => {
            el.replaceChildren(inputMoneyDivClone[index])
          })
        })

      const image = canvas.toDataURL('image/png', {
        allowTaint: false,
        useCORS: true,
      })

      const link = document.createElement('a')
      link.href = image

      const fileName = `${fileNameToCustom.value}.png`
      link.download = fileName

      link.click()
    }

    function exportToPDF() {
      // html2Pdf.value.htmlToPdfOptions.filename = fileNameToCustom.value
      // html2Pdf.value.generatePdf()
      const element = html2Pdf.value.$el.querySelector('section.content-wrapper')
      const inputMoneyDiv = element.querySelectorAll('#div-value-money')
      const inputMoneyDivClone = []
      inputMoneyDiv.forEach(el => {
        const firstChild = el.firstChild
        inputMoneyDivClone.push(firstChild.cloneNode(true))
        const newDiv = document.createElement('div')
        newDiv.textContent = formatCurrency(el.firstChild.defaultValue)
        newDiv.className = firstChild.className.replaceAll('\n', ' ').replace(/\s+/g, ' ')
        newDiv.classList.add('w-100')
        el.replaceChildren(newDiv)
      })
      const canvasDivPdf = element // this.$refs.pdfContainer
      const htmlWidth = canvasDivPdf.offsetWidth || 1200
      const topLeftMargin = 20
      const pdfWidth = (htmlWidth + (topLeftMargin * 2))
      const pdfHeight = ((pdfWidth * 1.5) + (topLeftMargin * 2))
      const htmlHeight = canvasDivPdf.offsetHeight || pdfHeight
      const canvasImgWidth = htmlWidth
      const canvasImgHeight = htmlHeight

      const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1

      html2canvas(canvasDivPdf, {
        // allowTaint: true,
        ...html2canvasOptions,
        ...(pdfOptions.html2canvas ?? {}),
      })
        .then(canvas => {
          canvas.getContext('2d')
          const imgData = canvas.toDataURL('image/png', 1.0)
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight], true)
          pdf.addImage(imgData, 'PNG', topLeftMargin, topLeftMargin, canvasImgWidth, canvasImgHeight)

          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= totalPDFPages; i++) {
            pdf.addPage(pdfWidth, pdfHeight)
            pdf.addImage(imgData, 'PNG', topLeftMargin, -(pdfHeight * i) + (topLeftMargin * 1.5), canvasImgWidth, canvasImgHeight)
          }

          const FileName = `${fileNameToCustom.value}.pdf`
          pdf.save(FileName)
        })
        .finally(() => {
          inputMoneyDiv.forEach((el, index) => {
            el.replaceChildren(inputMoneyDivClone[index])
          })
        })
    }

    function openModalRename(type) {
      typeToExport.value = type
      this.$bvModal.show('modal-rename-file')
    }

    function handleCloseTab() {
      window.close()
    }

    function cancelHandle() {
      this.$bvModal.hide('modal-rename-file')
    }

    const loadingToExport = ref(false)
    function exportHandle(type) {
      loadingToExport.value = true
      if (type === 'img') exportToImage()
      if (type === 'pdf') exportToPDF()
      setTimeout(() => {
        this.$bvModal.hide('modal-rename-file')
        loadingToExport.value = false
      }, 1000)
    }

    return {
      getBookingData,
      fetchBooking,
      reservationsData,
      loading,
      pdfOptions,
      config,
      timeHold,
      flights,
      paxs,
      // export ticket to image
      html2Pdf,
      exportToImage,
      exportToPDF,

      // export ticket to pdf
      pricingInfo,
      // exportToFile,

      handleCloseTab,
      combinateRef,

      openModalRename,
      cancelHandle,
      exportHandle,
      typeToExport,
      fileNameToCustom,
      loadingToExport,
    }
  },
  watch: {
    $route(to) {
      this.fetchBooking(to.params.id)
    },
  },
}
</script>
