export const COLORS = {
  YELLOW: '#EFAD02', // #FAC907: màu vàng cũ
  RED: '#E22326',
  GREEN: '#45B143',
  BLUE: '#0088FF',
  LIGHT_BLUE: '#40B0C9',
  BLACK: '#343434',
  PINK: '#CD2A7A',
  INDIGO: '#6517F6',
  PURPLE: '#6E43C4',
  ORANGE: '#F47D00',
  TEAL: '#48C995',
  GRAY: '#78808A',
}

export const BACKGROUND_COLORS = {
  YELLOW: '#f5ef8a',
  RED: '#F9D3D4',
  GREEN: '#DAEFD9',
  BLUE: '#DDF3FF',
  LIGHT_BLUE: '#D9EFF4',
  BLACK: '#D6D6D6',
  PINK: '#F5D4E4',
  INDIGO: '#E0D1FD',
  PURPLE: '#E2D9F3',
  ORANGE: '#FDE5CC',
  TEAL: '#DAF4EA',
  GRAY: '#E4E6E8',
}

/*
export const THEMES = {
  '#EFAD02': 'yellow',
  '#E22326': 'red',
  '#45B143': 'green',
  '#0088FF': 'blue',
  '#40B0C9': 'light_blue',
  '#343434': 'black',
  '#CD2A7A': 'pink',
  '#6517F6': 'indigo',
  '#6E43C4': 'purple',
  '#F47D00': 'orange',
  '#48C995': 'teal',
  '#78808A': 'gray',
}

shortean code by use Object.entries & reduce as following:
*/
export const THEMES = Object.entries(COLORS).reduce((themes, [key, value]) => {
  themes[value.toLowerCase()] = key.toLowerCase()
  return themes
}, {})

export const BACKGROUND_COLOR_THEMES = Object.entries(BACKGROUND_COLORS).reduce((themes, [key, value]) => ({ ...themes, [key.toLowerCase()]: value.toLowerCase() }), {})

export const TICKET_COLOR_OPTIONS = [
  {
    value: COLORS.YELLOW,
    label: 'ticket.colors.yellow',
  },
  {
    value: COLORS.RED,
    label: 'ticket.colors.red',
  },
  {
    value: COLORS.GREEN,
    label: 'ticket.colors.green',
  },
  {
    value: COLORS.BLUE,
    label: 'ticket.colors.blue',
  },
  {
    value: COLORS.LIGHT_BLUE,
    label: 'ticket.colors.lightBlue',
  },
  {
    value: COLORS.BLACK,
    label: 'ticket.colors.black',
  },
  {
    value: COLORS.PINK,
    label: 'ticket.colors.pink',
  },
  {
    value: COLORS.INDIGO,
    label: 'ticket.colors.indigo',
  },
  {
    value: COLORS.PURPLE,
    label: 'ticket.colors.purple',
  },
  {
    value: COLORS.ORANGE,
    label: 'ticket.colors.orange',
  },
  {
    value: COLORS.TEAL,
    label: 'ticket.colors.teal',
  },
  {
    value: COLORS.GRAY,
    label: 'ticket.colors.gray',
  },
]

export const TICKET_LANGUAGE_OPTIONS = [
  {
    value: 'vi',
    label: 'vietnam',
  },
  {
    value: 'en',
    label: 'english',
  },
]

export const ETICKET_TEMPLATE_OPTIONS = [
  { label: 'modern', value: 'MODERN' },
  { label: 'basic', value: 'BASIC' },
]
export const DEFAULT_CONFIG = {
  showAgency: true,
  showPrice: false,
  showTimeHold: true,
  showPayment: false,
  showStatus: false,
  showBookingClass: false,
  showPriceNetFare: false,
  showBackground: false,
  showBackgroundColor: false,
  showAgencyLogo: true,
  useQuillEditor: false,
  status: 'HOLD', // 'HOLD' || 'PAID'
  color: '#0088FF',
}

export const defaultNoteVn = `
<h4>
  <b style="color: red;">Quý khách xin lưu ý:</b>
</h4>
<ul>
  <li>
    Quý khách vui lòng sắp xếp thời gian có mặt tại sân bay đúng giờ (trước 90 phút cho chuyến bay nội địa và 180 phút cho chuyến bay quốc tế) để đảm bảo hoàn thành các thủ tục cho chuyến bay. Hãng hàng không sẽ không chấp nhận vận chuyển hành khách sau thời điểm đóng quầy.
  </li>
  <li>
    Giấy tờ tùy thân: CMND/CCCD còn hạn, Hộ chiếu (Passport), Giấy phép lái xe, Thẻ Đảng,...
  </li>
  <li>
    Từ 14 tuổi chưa có CMND/CCCD: Giấy xác nhận nhân thân (có giá trị trong 30 ngày).
  </li>
  <li>
    Dưới 14 tuổi: Giấy khai sinh bản chính hoặc bản sao y trích lục.
  </li>
  <li>
    Trẻ sơ sinh: Giấy khai sinh/Giấy chứng sinh (đối với trường hợp dưới 1 tháng tuổi chưa có giấy khai sinh).
  </li>
</ul>
<h4 class="ql-align-center">
  <b>Xin cảm ơn và chúc quý khách có một chuyến bay tốt đẹp!</b>
</h4>
`

export const defaultNoteEn = `
<h4>
  <b style="color: red;">Important note</b>
</h4>
<ul>
  <li>
    Please arrange to be at the airport on time (90 minutes in advance for domestic flights and 180 minutes in advance for international flights) to ensure completion of flight procedures. Airlines will not accept passengers after the check-in counter closing time.
  </li>
  <li>
    Identity documents: valid identity card; Passport; Driving license issued by Vietnamese competent agencies; Communist Party of Vietnam membership card;...
  </li>
  <li>
    Passengers over the age of 14 (years) without ID Card: Certifying personal identification form by local police or authorities citing passenger‘s permanent residence or temporary residence (shall be valid within 30 days from the date of confirmation).
  </li>
  <li>
    Passengers under the age of 14 (years): Birth Certificate must be the original or a copy certified by a notarial office.
  </li>
  <li>
    Infants: Birth Certificate/ Birth Certifying Form (infants under one [01] month old).
  </li>
</ul>
<h4 class="ql-align-center">
  <b>Thank you and have a nice flight!</b>
</h4>
`

export const FLIGHTS_INFO_SAMPLE = [
  {
    bookingCode: 'NYQWRN',
    trip: 'HAN-SGN',
    segments: [
      {
        from: 'HAN',
        to: 'DAD',
        departTime: '07:45',
        arrivalTime: '09:00',
        departDate: '22/09/2023',
        arrivalDate: '22/09/2023',
        departWeekday: 'T6',
        arrivalWeekday: 'T6',
        airline: 'VN',
        operating: 'BL',
        flight: 'VN267',
        airCraft: '320',
        mileage: 390,
        durationHM: '1hr(s)15min(s)',
        groupClass: 'Economy/ N',
        bookingClass: 'E',
        transitTime: '00:45',
      },
      {
        from: 'DAD',
        to: 'SGN',
        departTime: '09:45',
        arrivalTime: '11:45',
        departDate: '22/09/2023',
        arrivalDate: '22/09/2023',
        departWeekday: 'T6',
        arrivalWeekday: 'T6',
        airline: 'VN',
        operating: '',
        flight: 'VN480',
        airCraft: '320',
        mileage: 373,
        durationHM: '2hr(s)00min(s)',
        groupClass: 'Economy/ N',
        bookingClass: 'E',
        transitTime: null,
      },
    ],
  },
  {
    bookingCode: 'D49BX3',
    trip: 'SGN-HAN',
    segments: [
      {
        from: 'SGN',
        to: 'HAN',
        departTime: '12:00',
        arrivalTime: '15:15',
        departDate: '24/09/2023',
        arrivalDate: '24/09/2023',
        departWeekday: 'CN',
        arrivalWeekday: 'CN',
        airline: 'QH',
        operating: '',
        flight: 'QH242',
        airCraft: '321',
        mileage: 718,
        durationHM: '3hr(s)15min(s)',
        groupClass: 'Economy/ N',
        bookingClass: 'Y',
        transitTime: null,
      },
    ],
  },
]

export const PAXS_SAMPLE = [
  {
    passenger: 'ticket.paxTable.pax1',
    bags: 'ticket.paxTable.bagsTicket1',
  },
  {
    passenger: 'ticket.paxTable.pax2',
    bags: 'ticket.paxTable.bagsTicket2',
  },
  {
    passenger: 'ticket.paxTable.pax3',
    bags: 'ticket.paxTable.bagsTicket3',
  },
]

export const PRICE_SAMPLE = {
  vatRateValue: 0.1,
  totalAmountBeforeTax: 1000000,
  currency: 'VND',
}

export const FARE_BOOKING_DATA_ARRAY_SAMPLE = [
  {
    trip: 'Hà Nội (HAN) - Đà Nẵng (DAD), Đà Nẵng (DAD) - TP. Hồ Chí Minh (SGN)',
    departureDate: '2024-03-21T09:45:00+07:00',
    paxFare: [
      {
        paxType: 'ADULT',
        flight: 'Hà Nội (HAN) - Đà Nẵng (DAD), Đà Nẵng (DAD) - TP. Hồ Chí Minh (SGN)',
        departureDate: '2024-03-21T09:45:00+07:00',
        segmentId: '1, 2',
        amountPassenger: 2,
        fareBasisCode: 'TPXVNF9, RPXVNF9',
        expirationTime: '04/03/2024 | 09:54',
        lastDateToPurchase: '04/03/2024 | 21:39',
        feeService: 0,
        netPrice: 3276000,
        tax: 2540000,
        total: 5816000,
      },
      {
        paxType: 'CHILD',
        flight: 'Hà Nội (HAN) - Đà Nẵng (DAD), Đà Nẵng (DAD) - TP. Hồ Chí Minh (SGN)',
        departureDate: '2024-03-21T09:45:00+07:00',
        segmentId: '1, 2',
        amountPassenger: 1,
        fareBasisCode: 'TPXVNF9/CH10, RPXVNF9/CH10',
        expirationTime: '04/03/2024 | 09:54',
        lastDateToPurchase: '04/03/2024 | 21:39',
        feeService: 0,
        netPrice: 1476000,
        tax: 1138000,
        total: 2614000,
      },
    ],
    total: 8430000,
  },
  {
    trip: 'TP. Hồ Chí Minh (SGN) - Hà Nội (HAN)',
    departureDate: '2024-03-21T09:45:00+07:00',
    paxFare: [
      {
        paxType: 'ADULT',
        flight: 'TP. Hồ Chí Minh (SGN) - Hà Nội (HAN)',
        departureDate: '2024-03-21T09:45:00+07:00',
        segmentId: '1, 2',
        amountPassenger: 2,
        fareBasisCode: 'TPXVNF9, RPXVNF9',
        expirationTime: '04/03/2024 | 09:54',
        lastDateToPurchase: '04/03/2024 | 21:39',
        feeService: 0,
        netPrice: 3076000,
        tax: 2340000,
        total: 5416000,
      },
      {
        paxType: 'CHILD',
        flight: 'TP. Hồ Chí Minh (SGN) - Hà Nội (HAN)',
        departureDate: '2024-03-21T09:45:00+07:00',
        segmentId: '1, 2',
        amountPassenger: 1,
        fareBasisCode: 'TPXVNF9/CH10, RPXVNF9/CH10',
        expirationTime: '04/03/2024 | 09:54',
        lastDateToPurchase: '04/03/2024 | 21:39',
        feeService: 0,
        netPrice: 1476000,
        tax: 1138000,
        total: 2614000,
      },
    ],
    total: 8030000,
  },
]
